import {
  PublicClientApplication,
  Configuration,
  RedirectRequest,
  SilentRequest,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import authConfig from "../config/AuthConfig";

const msalConfig: Configuration = {
  auth: {
    clientId: authConfig.clientId,
    authority: authConfig.authority,
    redirectUri: authConfig.redirectUri,
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: true, // Set to true if you are having issues on IE11 or Edge
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

export default {
  async initialize() {
    await msalInstance.initialize();
    await msalInstance.handleRedirectPromise(); // This will no longer throw this error since initialize completed before this was invoked
    console.log("MSAL instance initialized");
  },

  async login() {
    if (!msalInstance) {
      throw new Error("MSAL instance is not initialized");
    }
    const loginRequest: RedirectRequest = {
      scopes: ["openid", "profile", "user.read"],
    };

    console.log("Starting login redirect");
    await msalInstance.loginRedirect(loginRequest);
  },

  logout() {
    if (!msalInstance) {
      throw new Error("MSAL instance is not initialized");
    }
    msalInstance.logoutRedirect();
  },

  async getToken(isRedirect: boolean) {
    if (!msalInstance) {
      throw new Error("MSAL instance is not initialized");
    }
    try {
      const accounts = msalInstance.getAllAccounts();
      if (accounts.length === 0) {
        // If no authenticated user, initiate login
        console.log("No authenticated user. Redirecting to login.");
        if (isRedirect) await this.login();
        return null;
      }

      const account = accounts[0];
      console.log(account);

      const tokenRequest: SilentRequest = {
        scopes: ["profile", "user.read"],
        account: account,
      };

      const response = await msalInstance.acquireTokenSilent(tokenRequest);
      return {
        accessToken: response.accessToken,
        account: account,
      };
    } catch (error) {
      // If acquireTokenSilent fails, fallback to interactive method
      if (error instanceof InteractionRequiredAuthError) {
        const tokenRequest: RedirectRequest = {
          scopes: ["profile", "user.read"],
        };
        msalInstance.acquireTokenRedirect(tokenRequest);
      } else {
        console.error("Token retrieval error:", error);
        throw error;
      }
    }
  },
};
