<template>
  <div class="redirecting-page">
    <p>{{ displayedLoadingText }}</p>
    <img id="logo" src="../assets/images/cad-assist-black.png" />
  </div>
</template>

<script lang="ts">
import { onMounted, ref, toRefs } from "vue";

export default {
  props: {
    loadingText: {
      type: String,
      required: true,
    },
  },
  setup(props: any) {
    const { loadingText } = toRefs(props);
    const displayedLoadingText = ref("");

    const updateLoadingText = () => {
      let dotCount = 0;
      setInterval(() => {
        displayedLoadingText.value =
          loadingText.value + ".".repeat(dotCount % 4);
        dotCount++;
      }, 500);
    };

    onMounted(() => {
      updateLoadingText();
    });

    return {
      displayedLoadingText,
    };
  },
};
</script>

<style scoped>
.redirecting-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-image: url("../assets/images/background.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.redirecting-page p {
  text-align: center;
}

#logo {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 75px;
  margin-bottom: 24px;
}
</style>
