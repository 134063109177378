export const formatDateTime = (datetimeString: string): string => {
  const date = new Date(datetimeString);
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  return `${day}/${month} ${hours}:${minutes}`;
};

export const newDate = (datetimeString: string): Date => {
  return new Date(datetimeString);
};
