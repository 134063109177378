import { GetterTree } from "vuex";
import { RootState } from "./state";
import { AgentModel, RoomModel, TagModel, UserModel } from "../types";
import { SessionModel } from "@/types/session";
import { ChatModel } from "@/types/chat";
import { newDate } from "@/utils/DateUtils";
import { RoleModel } from "@/types/role";
import { DashboardModel } from "@/types/dashboard";
import { FeedbackModel } from "@/types/feedback";

export enum GetterTypes {
  // DASHBOARD
  GET_DASHBOARD = "GET_DASHBOARD",
  // USERS
  GET_USER = "GET_USER",
  GET_USER_LIST = "GET_USER_LIST",
  // AGENTS
  GET_AGENT_LIST = "GET_AGENT_LIST",
  GET_SELECTED_AGENT = "GET_SELECTED_AGENT",
  // ROOMS
  GET_ROOM = "GET_ROOM",
  // SESSIONS
  GET_SESSION = "GET_SESSION",
  // CHATS
  GET_CHAT = "GET_CHAT",
  // TAGS
  GET_TAG = "GET_TAG",
  GET_SELECTED_TAG = "GET_SELECTED_TAG",
  // ROLES
  GET_ROLE = "GET_ROLE",
  // FEEDBACK
  GET_FEEDBACK = "GET_FEEDBACK",
  // UTILS
  GET_SEARCH = "GET_SEARCH",
  GET_LOADING = "GET_LOADING",
  GET_BLOCKING_INPUT = "GET_BLOCKING_INPUT",
}

export type Getters = {
  // DASHBOARD
  [GetterTypes.GET_DASHBOARD](state: RootState): DashboardModel;
  // USERS
  [GetterTypes.GET_USER](state: RootState): UserModel;
  [GetterTypes.GET_USER_LIST](state: RootState): UserModel[];
  // AGENTS
  [GetterTypes.GET_AGENT_LIST](state: RootState): AgentModel[];
  // ROOMS
  [GetterTypes.GET_ROOM](state: RootState): RoomModel;
  // SESSIONS
  [GetterTypes.GET_SESSION](state: RootState): SessionModel[];
  // CHATS
  [GetterTypes.GET_CHAT](state: RootState): ChatModel[];
  // TAGS
  [GetterTypes.GET_TAG](state: RootState): TagModel[];
  [GetterTypes.GET_SELECTED_TAG](state: RootState): string[];
  // ROLES
  [GetterTypes.GET_ROLE](state: RootState): RoleModel[];
  // FEEDBACK
  [GetterTypes.GET_FEEDBACK](state: RootState): FeedbackModel[];
  // UTILS
  [GetterTypes.GET_SEARCH](state: RootState): string;
  [GetterTypes.GET_LOADING](state: RootState): boolean;
  [GetterTypes.GET_BLOCKING_INPUT](state: RootState): boolean;
};

export const getters: GetterTree<RootState, any> & Getters = {
  // DASHBOARD
  [GetterTypes.GET_DASHBOARD](state) {
    return state.dashboard;
  },
  // USERS
  [GetterTypes.GET_USER_LIST](state) {
    return state.userList;
  },
  [GetterTypes.GET_USER](state) {
    return state.user;
  },
  // AGENTS
  [GetterTypes.GET_AGENT_LIST](state) {
    return state.agent
      .filter((item) => {
        return (
          item.name?.toLowerCase().includes(state.search.toLowerCase()) ||
          item.description?.String?.toLowerCase().includes(
            state.search.toLowerCase()
          )
        );
      })
      .filter((item) => {
        if (state.selectedTag.includes("all")) {
          return true;
        } else if (item.tagName === null || item.tagName === undefined) {
          return true;
        } else {
          const tagList = item.tagName.split(",");
          const isSublistPresent: boolean = state.selectedTag.some((_) => {
            return tagList.some((tag) => {
              return state.selectedTag.includes(tag.trim());
            });
          });

          return isSublistPresent;
        }
      })
      .sort((a, b) => {
        const aSort = a.isort?.Int32 ?? 999;
        const bSort = b.isort?.Int32 ?? 999;
        return aSort - bSort;
      });
  },
  [GetterTypes.GET_SELECTED_AGENT]: (state) => (agentId: number) => {
    return state.agent.find((item) => item.id === agentId);
  },
  // ROOMS
  [GetterTypes.GET_ROOM](state) {
    return state.room;
  },
  // SESSIONS
  [GetterTypes.GET_SESSION](state) {
    return state.session.sort(
      (a, b) => newDate(b.createdAt).getTime() - newDate(a.createdAt).getTime()
    );
  },
  // CHATS
  [GetterTypes.GET_CHAT](state) {
    return state.chat;
  },
  // TAGS
  [GetterTypes.GET_TAG](state) {
    return state.tag;
  },
  [GetterTypes.GET_SELECTED_TAG](state) {
    return state.selectedTag;
  },
  // ROLES
  [GetterTypes.GET_ROLE](state) {
    return state.role;
  },
  // FEEDBACK
  [GetterTypes.GET_FEEDBACK](state) {
    return state.feedback;
  },
  // UTILS
  [GetterTypes.GET_SEARCH](state) {
    return state.search;
  },
  [GetterTypes.GET_LOADING](state) {
    return state.isLoading;
  },
  [GetterTypes.GET_BLOCKING_INPUT](state) {
    return state.isBlockingInput;
  },
};
