export const removeWhiteSpace = (str: string): string => {
  return str[0] === " " ? str.slice(1) : str;
};

export const crossCheckData = (
  data1String: string,
  data2Array: any,
  list: any
) => {
  // Step 1: Split data1 (string) into an array of values
  const data1 = data1String.split(",").map((item) => item.trim());

  // Step 2: Extract the "name" fields from data2Array into an array
  const data2Names = data2Array.map((item: any) => item.name);

  // Step 3: Initialize arrays to store results
  const toDelete: any[] = [];
  const toAdd: any[] = [];
  const matchedToDelete: any[] = [];
  const matchedToAdd: any[] = [];

  // Step 4: Condition 1: Find items in data1 not present in data2Names (to delete)
  data1.forEach((item) => {
    if (!data2Names.includes(item)) {
      toDelete.push(item); // This item exists in data1 but not in data2, so delete
    }
  });

  // Step 5: Condition 2: Find new items in data2 that are not in data1 (to add)
  data2Names.forEach((item: any) => {
    if (!data1.includes(item)) {
      toAdd.push(item); // This item exists in data2 but not in data1, so add
    }
  });

  list.value.forEach((item: any) => {
    if (toAdd.includes(item.name)) {
      matchedToAdd.push(item); // Found a match, return full object
    }

    if (toDelete.includes(item.name)) {
      matchedToDelete.push(item); // Found a match, return full object
    }
  });

  return {
    matchedToAdd, // Items to delete from data1
    matchedToDelete, // New items to add from data2
  };
};
