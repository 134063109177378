import { createStore, StoreOptions } from "vuex";
import { RootState, state } from "./state";
import { mutations } from "./mutations";
import { actions } from "./actions";
import { getters } from "./getters";

const store: StoreOptions<RootState> = {
  state,
  mutations,
  actions,
  getters,
};

export default createStore(store);
