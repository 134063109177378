import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import AuthService from "@/helpers/AuthService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/HomeView.vue"),
  },
  {
    path: "/agent",
    name: "Agent",
    component: () => import("../views/AgentView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/whats-new",
    name: "What's New",
    component: () => import("../views/WhatsNewView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("../views/ProfileView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/agent/:id",
    name: "Chat",
    component: () => import("../views/ChatView.vue"),
    meta: {
      requiresAuth: true,
    },
    props: true,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("../views/DashboardView.vue"),
    meta: {
      requiresAuth: true,
    },
    props: true,
  },
  {
    path: "/agent/:id",
    name: "Chat",
    component: () => import("../views/ChatView.vue"),
    meta: {
      requiresAuth: true,
    },
    props: true,
  },
  {
    path: "/dashboard/:table",
    name: "Table",
    component: () => import("../views/TableView.vue"),
    meta: {
      requiresAuth: true,
    },
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.meta.requiresAuth) {
    // Check authentication before navigating to the route
    if (await AuthService.getToken(false)) {
      next();
    } else {
      // Redirect to login if not authenticated
      next({ name: "Home" });
      // setTimeout(() => {
      //   AuthService.login();
      // }, 1500);
    }
  } else {
    next();
  }
});

export default router;
