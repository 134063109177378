async function httpRequest<T>(
  method: string,
  uri: string,
  data: any = null,
  headers: Record<string, string> = {}
): Promise<T> {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const url = process.env.VUE_APP_API_BASE_URL + uri;

    const response = await fetch(url, {
      method: method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        ...headers, // Merge custom headers with default headers
      },
      body: data ? JSON.stringify(data) : null,
    });

    // Check if response is successful
    if (!response.ok) {
      throw new Error("HTTP error, status = " + response.status);
    }

    // Parse response data
    let responseData = await response.json();

    // Convert response body keys to lower camel case
    responseData = convertToCamelCase(responseData);

    // Return the modified response data
    return responseData;
  } catch (error) {
    // Handle errors
    console.error("Error:", error);
    throw error; // Rethrow error to propagate it to the caller
  }
}

function convertToCamelCase(data: any): any {
  if (Array.isArray(data)) {
    return data.map(convertToCamelCase);
  } else if (data !== null && typeof data === "object") {
    const camelCasedData: any = {};
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const camelCaseKey = key.replace(/_([a-z])/g, (_, letter) =>
          letter.toUpperCase()
        );

        camelCasedData[camelCaseKey] = convertToCamelCase(data[key]);
      }
    }
    return camelCasedData;
  }
  return data;
}

export default httpRequest;
