import { RoomModel } from "@/types/room";
import { AgentModel, TagModel, UserModel } from "../types/index";
import { SessionModel } from "@/types/session";
import { ChatModel } from "@/types/chat";
import { RoleModel } from "@/types/role";
import { DashboardModel } from "@/types/dashboard";
import { FeedbackModel } from "@/types/feedback";

export interface RootState {
  user: UserModel;
  agent: AgentModel[];
  room: RoomModel;
  session: SessionModel[];
  chat: ChatModel[];
  tag: TagModel[];
  role: RoleModel[];
  search: string;
  selectedTag: string[];
  isLoading: boolean;
  isBlockingInput: boolean;
  userList: UserModel[];
  dashboard: DashboardModel;
  feedback: FeedbackModel[];
}

export const state: RootState = {
  user: {} as UserModel,
  agent: [] as AgentModel[],
  room: {} as RoomModel,
  session: [] as SessionModel[],
  chat: [] as ChatModel[],
  tag: [] as TagModel[],
  role: [] as RoleModel[],
  search: "",
  selectedTag: ["all"] as string[],
  isLoading: false,
  isBlockingInput: false,
  userList: [] as UserModel[],
  dashboard: {} as DashboardModel,
  feedback: [] as FeedbackModel[],
};
