import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import HighchartsVue from "highcharts-vue";
import AuthService from "./helpers/AuthService";

const app = createApp(App);
app.config.globalProperties.$auth = AuthService;

app.use(store).use(router).use(HighchartsVue).mount("#app");
